@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  @apply font-Poppins scroll-smooth;
}
body {
  @apply box-border bg-gray-900 text-gray-100;
}
.btn-primary {
  @apply bg-cyan-600 font-semibold text-white md:mx-0 mx-auto rounded-full py-3 px-6 flex items-center gap-2;
}

.aboutImg::after {
  @apply content-[''] w-full left-0 top-0 rounded-xl rotate-12 h-full absolute bg-gray-600 -z-10;
}

.swiper-pagination-bullet {
  @apply bg-white;
  color: white;
}

.sliderPadding {
  padding-bottom: 30px;
}
.sliderMargin {
  display: flex;
  justify-content: left;
  margin-bottom: 10px;
}

input,
textarea {
  @apply bg-gray-700 outline-none rounded-lg p-3;
}
::placeholder {
  @apply text-lg;
}

::selection {
  @apply bg-cyan-600;
}
